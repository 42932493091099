<template>
  <div class="container-fluid">
    <Header />
    <div
      class="mx-auto mt-1 content-outer-container container-Tutorial"
      :class="{ 'content-dashboard': $store.state.isDashboard }"
    >
      <div class="mx-auto">
        <div class="wrapper-ifrem custom-wrapper-ifrem">
          <div class="row m-0">
            <div
              class="
                col-xl-12 col-lg-12 col-md-12
                pb-4
                wrapper-dashboard
                custom-wrapper-dashboard
              "
            >
              <div
                class="card-custom card-stretch gutter-b panel panel-default"
              >
                <div
                  class="d-flex align-items-center flex-wrap mr-2 text-title"
                >
                  {{ lbl['main-menu-tutorials'] }}
                </div>
              </div>
            </div>

            <div class="col-xl-12 col-lg-12 col-md-12 content-wrapper mb-5">
              <a-skeleton v-if="isSkeleton" active />
              <div v-else>
                <div v-if="isNodata">
                  <a-empty />
                </div>
                <div v-else>
                  <a-tabs
                    :default-active-key="activeKey ? activeKey : 1"
                    :active-key="activeKey ? activeKey : 1"
                    :tab-position="'left'"
                    @change="changeActiveKey"
                  >
                    <a-tab-pane
                      v-for="(item, index) in jsonTutorial"
                      :key="index + 1"
                      :tab="`#${
                        item.title[currentLocale == 'th' ? 'th' : 'en']
                      }`"
                    >
                      <!-- {{ item }} -->
                      <div v-if="activeKey == index + 1">
                        <div class="col-12">
                          <div
                            class="
                              d-flex
                              align-items-center
                              flex-wrap
                              mr-2
                              text-title
                              mt-2
                            "
                          >
                            {{
                              item
                                ? item.vdo[currentLocale == 'th' ? 'th' : 'en']
                                    .title
                                : ''
                            }}
                          </div>
                        </div>
                        <div
                          class="
                            col-xl-12 col-lg-12 col-md-12
                            content-wrapper
                            my-5
                          "
                        >
                          <div class="row d-flex align-center justify-center">
                            <div
                              class="
                                col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12
                              "
                            >
                              <div
                                v-if="
                                  item
                                    ? item.vdo[
                                        currentLocale == 'th' ? 'th' : 'en'
                                      ].link
                                    : false
                                "
                                class="box-vdo"
                              >
                                <video-player
                                  :poster="
                                    item
                                      ? item.vdo[
                                          currentLocale == 'th' ? 'th' : 'en'
                                        ].cover
                                      : null
                                  "
                                  :url="
                                    item
                                      ? item.vdo[
                                          currentLocale == 'th' ? 'th' : 'en'
                                        ].link
                                      : null
                                  "
                                />
                              </div>
                              <div v-else>
                                <a-empty />
                              </div>
                            </div>
                          </div>

                          <div class="box-vdo-title mt-4">
                            {{
                              item
                                ? item.vdo[currentLocale == 'th' ? 'th' : 'en']
                                    .title
                                : null
                            }}
                          </div>
                          <div class="box-vdo-detail mt-1">
                            {{
                              item
                                ? item.vdo[currentLocale == 'th' ? 'th' : 'en']
                                    .description
                                : null
                            }}
                          </div>
                          <div
                            v-if="item ? item.vdo.attachments : false"
                            class="box-vdo-file mt-3"
                          >
                            <div
                              v-for="(item_a, index_a) in item.vdo.attachments"
                              :key="index_a"
                            >
                              <img
                                src="@/assets/images/icon-attachment.svg"
                                alt=""
                              />
                              <a
                                class="website"
                                :href="item_a.link"
                                target="_blank"
                              >
                                {{ item_a.filename }}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a-tab-pane>
                  </a-tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import _ from 'lodash'
import Mixin from '@/mixin/Mixin'
import Header from '@/components/Layout/Header'
import Config from '@/helper/Config.js'
import Locale from '@/helper/locale.js'
import VideoPlayer from '@/plugins/VideoPlayer.js'

export default {
  name: 'Tutorial',
  components: {
    Header,
    VideoPlayer,
  },
  mixins: [Mixin],
  data: function () {
    return {
      jsonTutorial: [],
      currentLocale: Locale.getLocaleShort(),
      dataDetail: {},
      activeKey: 1,
      isSkeleton: true,
      isNodata: true,
    }
  },
  created() {
    this.handleLoading(false)
    this.init()
  },
  methods: {
    init() {
      this.getConfigTutorial()
    },
    getConfigTutorial() {
      Config.getConfigTutorial().then(res => {
        if (res != false) {
          this.isSkeleton = false
          if (res.result.length > 0) {
            this.isNodata = false
            this.jsonTutorial = res.result
          } else {
            this.isNodata = true
            this.jsonTutorial = []
          }
        } else {
          this.isSkeleton = false
          this.isNodata = true
        }
      })
    },
    changeActiveKey(e) {
      this.activeKey = e
    },
  },
}
</script>

<style lang="scss">
@import '@/style/main.scss';

.container-Tutorial {
  .custom-wrapper-dashboard {
    padding: 30px;
  }
  .content-wrapper {
    padding: 0 30px;
  }
  .text-title {
    font-size: 24px;
    line-height: 1.18;
    letter-spacing: 0.25px;
    color: $black;
  }
  .box-cover {
    height: 304px;
    width: 100%;
    border: 1px solid $border;
  }
  .box-vdo {
    border: 0.5px solid $color-grey-c0;
    padding: 0.2px;
  }
  .box-vdo-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: $color-black-4a;
  }
  .box-vdo-detail {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: $color-black-63;
  }

  .ant-tabs-nav {
    font-size: 16px;
  }

  .ant-tabs-nav .ant-tabs-tab:hover {
    color: $color-orange-1 !important;
  }

  .ant-tabs-nav .ant-tabs-tab-active {
    color: $color-orange-1 !important;
    font-weight: 500;
  }

  .ant-tabs .ant-tabs-left-bar {
    max-width: 300px;
  }
  .ant-tabs .ant-tabs-left-bar .ant-tabs-tab {
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .ant-tabs-ink-bar {
    background-color: $color-orange-1 !important;
  }
  .back-btn {
    border: solid 1px $text-dark-gray;
    border-radius: 8px;
    padding: 0px 20px;
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 36px;
  }

  //แนวตั้ง mobile
  @media only screen and (min-device-width: 625px) and (max-device-width: 767px) and (orientation: portrait) {
    .ant-tabs .ant-tabs-left-bar {
      max-width: 150px;
    }
    .box-cover {
      height: 100px;
    }
  }

  @media only screen and (max-device-width: 624px) and (orientation: portrait) {
    .ant-tabs .ant-tabs-left-bar {
      max-width: 120px;
    }
    .box-cover {
      height: 100px;
    }
  }
}
</style>
